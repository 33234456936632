<template>
    <b-card :title="$t('add_new_customer')">
        <form @submit.prevent="addUser" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-tabs content-class="mt-3">
                <b-tab :title="$t('info')" active>
                    <b-row>
                        <b-col sm="6">
                            <div class="form-group">
                                <label for="first_name">{{ $t('first_name') }}<span class="text-danger">*</span></label>
                                <input type="text" name="first_name" class="form-control" v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                <has-error :form="form" field="first_name" />
                            </div>
                            <div class="form-group">
                                <label for="last_name">{{ $t('last_name') }}<span class="text-danger">*</span></label>
                                <input type="text" name="last_name" class="form-control" v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                <has-error :form="form" field="last_name" />
                            </div>
                            <div class="form-group">
                                <label for="surname">{{ $t('patronimic_name') }}</label>
                                <input type="text" name="surname" class="form-control" v-model="form.surname" :class="{ 'is-invalid': form.errors.has('surname') }">
                                <has-error :form="form" field="surname" />
                            </div>
                            <div class="form-group">
                                <label for="phone">{{ $t('phone') }}<span class="text-danger">*</span></label>
                                <input v-model="form.phone" v-mask="mask" class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }" />
                                <has-error :form="form" field="phone" />
                            </div>
                            <div class="form-group">
                                <label for="email">{{ $t('email') }}<span class="text-danger">*</span></label>
                                <input type="text" name="email" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
                                <has-error :form="form" field="email" />
                            </div>
                            <div class="form-group">
                                <label for="group_id">{{ $t('group') }}<span class="text-danger">*</span></label>
                                <select v-model="form.group_id" name="group_id" class="form-control"  :class="{ 'is-invalid': form.errors.has('group_id') }">
                                    <option v-for="(item, index) in groups" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                                <has-error :form="form" field="email" />
                            </div>
                        </b-col>
                        <b-col md="6">
                            <div class="form-group">
                                <label for="company">{{ $t('company') }}</label>
                                <input type="text" name="company" class="form-control" v-model="form.company" :class="{ 'is-invalid': form.errors.has('company') }">
                                <has-error :form="form" field="company" />
                            </div>
                            <div class="form-group">
                                <label for="description">{{ $t('description') }}</label>
                                <textarea name="description" class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                                <has-error :form="form" field="description" />
                            </div>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- delivery data -->
                <b-tab :title="$t('delivery_addresses')">
                    <b-row>
                        <b-col md="6">
                            <div class="form-group">
                                <label for="delivery_method">{{ $t('delivery_method') }}</label>
                                <select
                                name="delivery_method"
                                class="form-control"
                                v-model="deliveryData.delivery_id"
                                @change="changeDeliveryMethod">
                                    <option v-for="(item, index) in deliveries" :key="index" :value="item.id" :data-slug="item.id">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="form-group" v-if="delivery_method === 'nova-posta'">
                                <label for="delivery_type">{{ $t('delivery_type') }}</label>
                                <select
                                v-model="deliveryData.delivery_type"
                                name="delivery_type"
                                class="form-control"
                                @change="changeDeliveryType">
                                    <option v-for="(item, index) in deliveryTypes" :value="item.id" :key="index">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="form-group" v-if="delivery_method === 'nova-posta'">
                                <label for="city">{{ $t('city_recipient') }}</label>
                                <model-list-select
                                    :list="cities"
                                    option-value="ref"
                                    option-text="name"
                                    v-model="deliveryData.city_recipient"
                                    :placeholder="$t('nothing_selected')"
                                    @searchchange="searchCity"></model-list-select>
                            </div>
                            <div class="form-group" v-if="delivery_type === 'WarehouseWarehouse'">
                                <label for="warehouse">{{ $t('warehouse_or_street') }}</label>
                                <model-list-select
                                    :list="warehouses"
                                    option-value="Ref"
                                    option-text="Description"
                                    v-model="deliveryData.warehouse_ref"
                                    :placeholder="$t('nothing_selected')"
                                    @searchchange="searchWarehouse"></model-list-select>
                            </div>
                            <div class="form-group" v-if="delivery_type === 'WarehouseDoors'">
                                <label for="address">Вулиця</label>
                                <model-list-select
                                    :list="streets"
                                    option-value="ref"
                                    option-text="name"
                                    v-model="deliveryData.street_ref"
                                    :placeholder="$t('nothing_selected')"
                                    @searchchange="searchStreet"></model-list-select>
                            </div>
                            <b-input-group v-if="delivery_type === 'WarehouseDoors'">
                                <b-form-input placeholder="№ дому" v-model="deliveryData.house_number"></b-form-input>
                                <b-form-input placeholder="№ квартири" v-model="deliveryData.apartment_number"></b-form-input>
                            </b-input-group>
                            <!-- samoviviz -->
                            <div v-if="delivery_method === 'samoviviz'" class="form-group">
                                <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
                                <input type="text" class="form-control" v-model="deliveryData.city_name">
                            </div>
                            <!-- ukr-posta -->
                            <div v-if="delivery_method === 'ukr-posta'" class="form-group">
                                <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
                                <input type="text" class="form-control" v-model="deliveryData.city_name">
                            </div>
                            <div v-if="delivery_method === 'ukr-posta'" class="form-group">
                                <label for="zip_code">{{ $t('index') }}</label>
                                <input type="text" name="zip_code" class="form-control" v-model="deliveryData.zip_code">
                            </div>
                            <b-form-group :label="$t('recipient')">
                                <b-form-radio v-model="deliveryData.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                                <b-form-radio v-model="deliveryData.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
                            </b-form-group>
                            <b-button v-if="delivery_type || deliveryData.city_name" size="sm" variant="warning" @click="addDeliveryAddress">{{ $t('add_address') }}</b-button>
                        </b-col>
                        <b-col md="6">
                            <ul class="list-unstyled" v-for="(del, idx) in deliveryInfo" :key="idx">
                                <li v-for="(d, index) in del" :key="index">
                                    <strong>{{ d }}</strong>
                                </li>
                                <li><b-button size="sm" variant="danger" @click="removeDeliveryFromList(idx)"><fa icon="trash" /></b-button></li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
            <br>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('add_new_customer') }
    },
    components: {
        ModelListSelect
    },
    data: () => ({
        mask: ['+38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        form: new Form({
            first_name: '',
            last_name: '',
            surname: '',
            phone: '',
            email: '',
            company: '',
            description: '',
            delivery_data: [],
            group_id: 1
        }),
        delivery_method: '',
        delivery_type: '',
        groups: null,
        selectedCity: '',
        cities: [],
        searchCityText: '',
        warehouses: [],
        searchWarehouseText: '',
        streets: [],
        deliveryData: {
            entity: 'individual', // legal
            delivery_id: '',
            delivery_type: '',
            city_recipient: '',
            street_ref: '',
            house_number: '',
            apartment_number: '',
            city_name: '',
            zip_code: ''
        },
        deliveryInfo: []
    }),
    mounted () {
        this.getGroups()
        this.setDeliveries()
    },
    computed: {
        ...mapGetters({
            deliveries: 'order/deliveries',
            deliveryTypes: 'order/deliveryTypes'
        })
    },
    methods: {
        ...mapActions({
            setDeliveries: 'order/setDeliveries'
        }),
        async addUser () {
            // add new user
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'customers').then(resp => {
                console.log(resp)
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
            this.$router.push({ name: 'customers' })
        },
        async getGroups () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'customer-groups')
            this.groups = data.data
        },
        async searchCity (value) {
            if (value.length > 1) {
                this.searchCityText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'city/search', { key: value }).then(resp => {
                    this.cities = resp.data.data
                    this.warehouses = []
                })
            }
        },
        async searchWarehouse (value) {
            this.$root.$refs.loading.start()
            if (this.deliveryData.city_recipient) {
                if (value.length > 0) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.deliveryData.city_recipient, key: value })
                    this.warehouses = data.data
                }
            }
            this.$root.$refs.loading.finish()
        },
        async searchStreet (value) {
            this.$root.$refs.loading.start()
            if (this.deliveryData.city_recipient) {
                if (value.length > 1) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/street', { city: this.deliveryData.city_recipient, key: value })
                    this.streets = data
                }
            }
            this.$root.$refs.loading.finish()
        },
        changeDeliveryMethod (e) {
            this.delivery_method = ''
            this.delivery_type = ''
            // this.deliveryData.delivery_id = ''
            this.deliveryData.delivery_type = ''
            this.deliveryData.city_recipient = ''
            this.deliveryData.street_ref = ''
            this.deliveryData.house_number = ''
            this.deliveryData.apartment_number = ''
            this.deliveryData.city_name = ''
            this.deliveryData.zip_code = ''

            const id = e.target.value
            const delivery = this.deliveries.find(d => d.id === id)
            this.delivery_method = delivery.slug
        },
        changeDeliveryType (e) {
            const id = e.target.value
            if (this.delivery_method === 'nova-posta') {
                const deliveryType = this.deliveryTypes.find(d => d.id === id)
                this.delivery_type = deliveryType.id
            }
        },
        removeDeliveryFromList (index) {
            this.deliveryInfo.splice(index, 1)
            this.form.delivery_data.splice(index, 1)
        },
        addDeliveryAddress () {
            const delivery = this.deliveries.find(d => d.id === this.deliveryData.delivery_id)
            const city = this.cities.find(c => c.ref === this.deliveryData.city_recipient)
            const warehouse = this.warehouses.find(w => w.Ref === this.deliveryData.warehouse_ref)
            const street = this.streets.find(s => s.Ref === this.deliveryData.street_ref)
            if (this.deliveryInfo.length > 0) {
                const result = this.deliveryInfo.some(d => d.includes(delivery.name))
                if (!result) {
                    this.deliveryInfo.push([
                        (delivery && delivery.name) ? delivery.name : null,
                        (this.deliveryData.delivery_type === 'WarehouseWarehouse') ? 'Склад-Склад' : 'Склад-Двері',
                        (city && city.name) ? city.name : null,
                        (warehouse && warehouse.Description) ? warehouse.Description : null,
                        (street && street.Description) ? street.Description : null,
                        this.deliveryData.city_name,
                        this.deliveryData.zip_code,
                        this.deliveryData.entity === 'individual' ? 'Фіз.особа' : 'Юр.особа'
                    ])
                    this.form.delivery_data.push(this.deliveryData)
                    this.form.delivery_data.find(d => {
                        if (d.delivery_id === this.deliveryData.delivery_id) {
                            d.city_name = (city && city.name) ? city.name : null
                            d.street_name = (street && street.Description) ? street.Description : null
                            d.warehouse_name = (warehouse && warehouse.Description) ? warehouse.Description : null
                        }
                    })
                }
            } else {
                this.deliveryInfo.push([
                    (delivery && delivery.name) ? delivery.name : null,
                    (this.deliveryData.delivery_type === 'WarehouseWarehouse') ? 'Склад-Склад' : 'Склад-Двері',
                    (city && city.name) ? city.name : null,
                    (warehouse && warehouse.Description) ? warehouse.Description : null,
                    (street && street.Description) ? street.Description : null,
                    this.deliveryData.city_name,
                    this.deliveryData.zip_code,
                    this.deliveryData.entity === 'individual' ? 'Фіз.особа' : 'Юр.особа'
                ])
                this.form.delivery_data.push(this.deliveryData)
                this.form.delivery_data.find(d => {
                    if (d.delivery_id === this.deliveryData.delivery_id) {
                        d.city_name = (city && city.name) ? city.name : null
                        d.street_name = (street && street.Description) ? street.Description : null
                        d.warehouse_name = (warehouse && warehouse.Description) ? warehouse.Description : null
                    }
                })
            }
        }
    }
}
</script>
